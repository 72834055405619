import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useClient } from '../ClientContext';

const styles = {
  container: { display: 'flex', alignItems: 'center', backgroundColor: '#FFFFFF', borderRadius: '8px', mb: '32px' },
  box: { p: '32px', flex: 1 },
  headerText: {
    fontSize: '14px',
    fontWeight: 400,
    mb: '8px',
    lineHeight: '22px',
    letterSpacing: '-0.14px',
    color: '#667085',
  },
  bodyText: { fontWeight: 700, fontSize: '20px', lineHeight: '24px', letterSpacing: '-0.2px' },
};

const UserManagementSummary = ({ totalUsers, tidalOrSxUsers, beatdappUsers, totalAdmins }) => {
  const { selectedClient } = useClient();
  const adjustedTidalOrSxUsers = Math.max(0, tidalOrSxUsers - beatdappUsers);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.box}>
        <Typography variant="body2" sx={styles.headerText}>
          Total Users
        </Typography>
        <Typography variant="h4" sx={styles.bodyText}>
          {totalUsers}
        </Typography>
      </Box>

      <Divider orientation="vertical" flexItem />

      <Box sx={styles.box}>
        <Typography variant="body2" sx={styles.headerText}>
          {selectedClient === 'SoundExchange' ? 'SX Only Users' : 'Tidal Only Users'}
        </Typography>
        <Typography variant="h4" sx={styles.bodyText}>
          {adjustedTidalOrSxUsers}
        </Typography>
      </Box>

      <Divider orientation="vertical" flexItem />

      <Box sx={styles.box}>
        <Typography variant="body2" sx={styles.headerText}>
          Beatdapp Users
        </Typography>
        <Typography variant="h4" sx={styles.bodyText}>
          {beatdappUsers}
        </Typography>
      </Box>

      <Divider orientation="vertical" flexItem />

      <Box sx={styles.box}>
        <Typography variant="body2" sx={styles.headerText}>
          Total Admins
        </Typography>
        <Typography variant="h4" sx={styles.bodyText}>
          {totalAdmins}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserManagementSummary;
