import React, { useState, useEffect } from 'react';
import { Box, Button, Divider } from '@mui/material';
import { House, LogOut, User } from 'lucide-react';
import { Logo } from './Logo';
import ClientSelection from './ClientSelection';
import { useLocation, Link } from 'react-router-dom'; // Import useLocation and Link
import { useAuth0 } from '@auth0/auth0-react';

export function SideNav() {
  const location = useLocation();
  const currentPath = location.pathname;
  const { logout, isAuthenticated } = useAuth0();
  const isSelected = (href) => currentPath === href;
  const [clients, setClients] = useState([]);
  const getDefaultClient = (path) => {
    if (path === '/admin' || path === '/') return 'All Dashboards';
    return null;
  };

  useEffect(() => {
    if (currentPath === '/admin' || currentPath === '/') {
      setClients(['All Dashboards', 'Tidal', 'SoundExchange']);
    } else {
      setClients(['Tidal', 'SoundExchange']);
    }
  }, [currentPath]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: 240,
        minWidth: 240,
        maxWidth: 240,
        backgroundColor: '#fff',
        p: 2,
        pt: '24px',
        border: '1px solid #e0e0e0',
      }}
    >
      {/* Logo Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid var(--mui-palette-beatdapp-neutral-medium)',
          width: 56,
          height: 56,
          background: 'linear-gradient(180deg, #772EDD 0%, #381767 100%)',
          borderRadius: '100%',
          mb: '24px',
        }}
      >
        <Logo src={'https://cdn1.beatdapp.com/icons/logo/BeatdappMark.svg'} height={27} width={27} />
      </Box>

      {/* Home Button */}
      <Box sx={{ width: '100%', px: 2 }}>
        <Button
          startIcon={<House />}
          fullWidth
          component={Link}
          to="/admin"
          sx={{
            justifyContent: 'flex-start',
            padding: '7px 16px',
            backgroundColor: isSelected('/admin') ? '#F9F4FF' : 'transparent',
            color: '#772EDD',
            borderRadius: '8px',
            textTransform: 'none',
            fontWeight: 'bold',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#E6DBFF',
              boxShadow: 'none',
            },
          }}
        >
          Home
        </Button>
      </Box>

      <Divider sx={{ width: '100%', my: 2 }} />

      {/* ClientSelection Component */}
      <ClientSelection clients={clients} defaultClient={getDefaultClient(currentPath)} />

      {/* User Management Button */}
      <Box sx={{ width: '100%', px: 2, mt: 2 }}>
        <Button
          startIcon={<User />}
          fullWidth
          component={Link}
          to="/user-management"
          sx={{
            justifyContent: 'flex-start',
            padding: '7px 16px',
            backgroundColor: isSelected('/user-management') ? '#F9F4FF' : 'transparent',
            color: '#772EDD',
            borderRadius: '8px',
            textTransform: 'none',
            fontWeight: 'bold',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#E6DBFF',
              boxShadow: 'none',
            },
          }}
        >
          User Management
        </Button>
      </Box>

      {/* Cache & Maintenance Button */}
      <Box sx={{ width: '100%', px: 2, mt: 2 }}>
        <Button
          startIcon={<User />} // Consider changing the icon if necessary
          fullWidth
          component={Link}
          to="/cache-maintenance"
          sx={{
            justifyContent: 'flex-start',
            padding: '7px 16px',
            backgroundColor: isSelected('/cache-maintenance') ? '#F9F4FF' : 'transparent',
            color: '#772EDD',
            borderRadius: '8px',
            textTransform: 'none',
            fontWeight: 'bold',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#E6DBFF',
              boxShadow: 'none',
            },
          }}
        >
          Cache & Maintenance
        </Button>
      </Box>

      <Box sx={{ flexGrow: 1 }} />

      {isAuthenticated && (
        <Box sx={{ width: '100%', px: 2, mt: 2, mb: 4 }}>
          <Button
            startIcon={<LogOut />}
            fullWidth
            onClick={() => logout({ returnTo: window.location.origin })}
            sx={{
              justifyContent: 'flex-start',
              padding: '7px 16px',
              backgroundColor: 'transparent',
              color: '#772EDD',
              borderRadius: '8px',
              textTransform: 'none',
              fontWeight: 'bold',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#E6DBFF',
                boxShadow: 'none',
              },
            }}
          >
            Logout
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default SideNav;
