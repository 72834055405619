import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
} from '@mui/material';
import { UserPlus, Trash, X, User } from 'lucide-react';
import { useClient } from '../ClientContext';

const MODAL_TYPE_ADD = 'Add New User';
const MODAL_TYPE_UPDATE = 'Update User';
const MODAL_TYPE_DELETE = 'Delete User';

const UserManagementModal = ({ modalType, open, onClose, selectedUser, accessToken }) => {
  const styles = {
    dialogTitleBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: '32px 16px',
    },
    iconBox: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    deleteContent: {
      padding: '32px',
    },
    formBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      padding: '16px 32px',
    },
    footerBox: (modalType) => ({
      display: 'flex',
      justifyContent: modalType === MODAL_TYPE_DELETE ? 'flex-end' : 'space-between',
      alignItems: 'center',
      px: '16px',
      pb: '32px',
      pt: '16px',
    }),
    requiredFieldText: {
      fontStyle: 'italic',
      color: 'red',
    },
    cancelButton: {
      color: '#475467',
      borderColor: '#475467',
      textTransform: 'none',
      boxShadow: 'none',
      fontWeight: 'bold',
    },
    actionButton: {
      backgroundColor: '#772EDD',
      textTransform: 'none',
      boxShadow: 'none',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: '#5C21BF',
      },
    },
    dialog: {
      borderRadius: '8px',
    },
    deleteUserText: {
      mb: 2,
    },
    userNameText: {
      fontWeight: 700,
      color: '#772EDD',
      mb: 2,
    },
    confirmationText: {
      color: '#475467',
    },
    requiredAsterisk: {
      color: 'red',
      ml: '-3px',
    },
    dialogActions: {
      justifyContent: 'flex-end',
    },
    warningText: {
      color: '#DC6803',
      fontSize: '14px',
      mt: '-8px',
      lineHeight: '22px',
    },
    warningBorder: {
      borderColor: '#DC6803',
    },
  };

  const roleMappings = {
    [process.env.REACT_APP_TIDAL_USER_ROLE_ID]: 'TIDAL-User',
    [process.env.REACT_APP_SX_USER_ROLE_ID]: 'SoundExchange-User',
    [process.env.REACT_APP_ADMIN_USER_ROLE_ID]: 'Admin',
  };

  const { selectedClient } = useClient();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState();
  const [showEmailWarning, setShowEmailWarning] = useState(false);
  const [isPasswordWeak, setIsPasswordWeak] = useState(false);

  useEffect(() => {
    if (modalType === MODAL_TYPE_UPDATE && selectedUser) {
      setName(selectedUser.name);
      setEmail(selectedUser.email);

      const fetchRole = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/admin/user/${selectedUser.auth0_id}/role`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );
          const roleId = response.data.roleId;
          setRole(roleMappings[roleId]);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching user role:', error);
          alert('Failed to fetch user role.');
        }
      };

      fetchRole();
    } else if (modalType === MODAL_TYPE_ADD) {
      clearForm();
    }
  }, [modalType, selectedUser]);

  const clearForm = () => {
    setName('');
    setEmail('');
    setPassword('');
    setRole('');
    setShowEmailWarning(false);
    setIsPasswordWeak(false);
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);

    if (emailValue.includes('@')) {
      const emailDomain = emailValue.substring(emailValue.indexOf('@')).toLowerCase();
      const roleDomainMapping = {
        'TIDAL-User': '@tidal.com',
        'SoundExchange-User': '@soundexchange.com',
        Admin: '',
      };

      const expectedDomain = roleDomainMapping[role] || '';
      setShowEmailWarning(expectedDomain && emailDomain !== expectedDomain.toLowerCase());
    } else {
      setShowEmailWarning(false);
    }
  };

  const validatePassword = (password) => {
    const isStrong = password.length >= 8 && /[0-9]/.test(password) && /[!@#$%^&*(),.?":{}|<>]/.test(password);
    setIsPasswordWeak(!isStrong);
  };

  const handlePasswordChange = (event) => {
    const passwordValue = event.target.value;
    setPassword(passwordValue);
    validatePassword(passwordValue);
  };

  const handleSubmit = async () => {
    try {
      const endPoint =
        modalType === MODAL_TYPE_UPDATE
          ? `${process.env.REACT_APP_BACKEND_URL}/admin/user/${selectedUser.id}`
          : `${process.env.REACT_APP_BACKEND_URL}/admin/create-user`;

      const method = modalType === MODAL_TYPE_UPDATE ? 'PATCH' : 'POST';

      const roleMapping = {
        'TIDAL-User': 'TidalUser',
        'SoundExchange-User': 'SXUser',
        Admin: 'Admin',
      };

      const mappedRole = roleMapping[role] || role;
      const response = await axios({
        method,
        url: endPoint,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          name,
          email,
          role: mappedRole,
          password,
        },
      });

      if (response.status === 200 || response.status === 201) {
        alert(modalType === MODAL_TYPE_UPDATE ? 'User updated successfully' : 'User created successfully');
        clearForm();
        onClose();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error with ${modalType === MODAL_TYPE_UPDATE ? 'updating' : 'creating'} user:`, error);
      alert(`Failed to ${modalType === MODAL_TYPE_UPDATE ? 'update' : 'create'} user`);
    }
  };

  const renderIcon = () => {
    switch (modalType) {
      case MODAL_TYPE_ADD:
        return <UserPlus size={24} />;
      case MODAL_TYPE_DELETE:
        return <Trash size={24} />;
      case MODAL_TYPE_UPDATE:
        return <User size={24} />;
      default:
        return null;
    }
  };

  const getAvailableRoles = () => {
    switch (selectedClient) {
      case 'Tidal':
        return ['TIDAL-User', 'Admin'];
      case 'SoundExchange':
        return ['SoundExchange-User', 'Admin'];
      default:
        return ['Admin'];
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        clearForm();
        onClose();
      }}
      fullWidth
      maxWidth="sm"
      sx={styles.dialog}
    >
      <DialogTitle sx={{ padding: 0 }}>
        <Box sx={styles.dialogTitleBox}>
          <Box sx={styles.iconBox}>
            {renderIcon()}
            <Typography variant="h5" fontWeight="bold">
              {modalType}
            </Typography>
          </Box>
          <IconButton
            onClick={() => {
              clearForm();
              onClose();
            }}
            size="small"
          >
            <X size={24} />
          </IconButton>
        </Box>
      </DialogTitle>
      {modalType === MODAL_TYPE_DELETE ? (
        <DialogContent dividers sx={styles.deleteContent}>
          <Typography sx={styles.deleteUserText}>You are about to delete this user:</Typography>
          <Typography sx={styles.userNameText}>
            {selectedUser?.name} ({selectedUser?.email})
          </Typography>
          <Typography sx={styles.confirmationText}>
            You cannot undo this action. Are you sure you want to proceed?
          </Typography>
        </DialogContent>
      ) : (
        <DialogContent dividers sx={{ padding: 0 }}>
          <Box component="form" sx={styles.formBox}>
            <TextField
              label={
                <Box component="span">
                  Name{' '}
                  <Typography component="span" sx={styles.requiredAsterisk}>
                    *
                  </Typography>
                </Box>
              }
              placeholder="Enter full name of user"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              label={
                <Box component="span">
                  Email{' '}
                  <Typography component="span" sx={styles.requiredAsterisk}>
                    *
                  </Typography>
                </Box>
              }
              placeholder="Enter email of user"
              fullWidth
              value={email}
              onChange={handleEmailChange}
              sx={showEmailWarning ? styles.warningBorder : {}}
            />
            {showEmailWarning && (
              <Typography sx={styles.warningText}>
                Are you sure this is the correct email ({email.substring(email.indexOf('@'))}) for the {role} dashboard?
              </Typography>
            )}
            <FormControl fullWidth>
              <InputLabel>User Role</InputLabel>
              <Select value={role} onChange={(e) => setRole(e.target.value)} label="User Role">
                {getAvailableRoles().map((roleOption) => (
                  <MenuItem key={roleOption} value={roleOption}>
                    {roleOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label={
                <Box component="span">
                  Password{' '}
                  <Typography component="span" sx={styles.requiredAsterisk}>
                    *
                  </Typography>
                </Box>
              }
              type="password"
              placeholder="Enter a strong password"
              fullWidth
              value={password}
              onChange={handlePasswordChange}
              error={isPasswordWeak}
              helperText={
                isPasswordWeak ? 'Password is too weak (minimum 8 characters, 1 number, 1 special character)' : ''
              }
            />
          </Box>
        </DialogContent>
      )}
      <Box sx={styles.footerBox(modalType)}>
        {modalType !== MODAL_TYPE_DELETE && <Typography sx={styles.requiredFieldText}>* Required field</Typography>}
        <DialogActions sx={styles.dialogActions}>
          {modalType === MODAL_TYPE_DELETE ? (
            <>
              <Button
                onClick={() => {
                  clearForm();
                  onClose();
                }}
                variant="outlined"
                sx={styles.cancelButton}
              >
                Cancel
              </Button>
              <Button onClick={onClose} variant="contained" color="error">
                Delete User
              </Button>
            </>
          ) : (
            <>
              <Button onClick={clearForm} variant="outlined" sx={styles.cancelButton}>
                Clear All
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                sx={styles.actionButton}
                disabled={isPasswordWeak}
              >
                {modalType === MODAL_TYPE_ADD ? 'Add User' : 'Update User'}
              </Button>
            </>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default UserManagementModal;
