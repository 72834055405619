import React from 'react';
import { Button, Typography, Container, Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { LogOut } from 'lucide-react';

export function AccessDenied() {
  const { logout } = useAuth0();

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundImage:
          'url(https://cdn1.beatdapp.com/marketing-site/compressed/fraud-checker-left-banner-background-min.webp)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="sm" sx={{ textAlign: 'center', backdropFilter: 'blur(4px)' }}>
        <Box
          sx={{
            padding: '40px',
            borderRadius: '12px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
            Access Denied
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '24px' }}>
            You do not have permission to access this page.
          </Typography>
          <Button
            variant="contained"
            startIcon={<LogOut />}
            onClick={() => logout({ returnTo: window.location.origin })}
            sx={{
              padding: '10px 20px',
              backgroundColor: '#772EDD',
              color: '#fff',
              textTransform: 'none',
              fontWeight: 'bold',
              borderRadius: '8px',
              boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
              '&:hover': {
                backgroundColor: '#6a25c6',
              },
            }}
          >
            Back to Login
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
