import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Admin from './pages/Admin';
import UserManagement from './pages/UserManagement';
import CacheAndMaintenance from './pages/CacheAndMaintenance';
import ProtectedRoute from './components/ProtectedRoute'; // Ensure the correct path
import { AccessDenied } from './components/AccessDenied';

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect, user, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    };

    if (!accessToken) {
      fetchAccessToken();
    }

    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }

    if (isAuthenticated && accessToken) {
      (async () => {
        try {
          await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/sync-user`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name: user.name,
              email: user.email,
              role: user[process.env.REACT_APP_AUTH0_ROLE_CLAIMS] || [],
              auth0Id: user.sub,
            }),
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching access token:', error);
        }
      })();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, user, getAccessTokenSilently]);

  if (!isAuthenticated) {
    return <div></div>;
  }

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute requiredRole="Admin">
                <Admin user={user} accessToken={accessToken} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute requiredRole="Admin">
                <Admin user={user} accessToken={accessToken} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-management"
            element={
              <ProtectedRoute requiredRole="Admin">
                <UserManagement user={user} accessToken={accessToken} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cache-maintenance"
            element={
              <ProtectedRoute requiredRole="Admin">
                <CacheAndMaintenance user={user} accessToken={accessToken} />
              </ProtectedRoute>
            }
          />
          <Route path="/access-denied" element={<AccessDenied />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
