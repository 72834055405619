import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tabs,
  Tab,
} from '@mui/material';
import { Mail, Check, MoreVertical, UserPlus } from 'lucide-react';
import UserManagementModal from './UserManagementModal';
import { useClient } from '../ClientContext';

const styles = {
  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '4px',
    width: '185px',
    padding: '4px 16px',
    backgroundColor: '#F3EAFF',
    color: '#381767',
    cursor: 'pointer',
  },
  tableCell: {
    fontWeight: 600,
    minWidth: '150px',
  },
  button: {
    backgroundColor: '#F3EAFF',
    color: '#381767',
  },
  tabs: {
    indicatorColor: {
      backgroundColor: '#772EDD',
      height: '4px',
    },
  },
  tab: {
    backgroundColor: 'transparent',
    '&.Mui-selected': {
      color: 'inherit',
    },
  },
  sentStatus: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#381767',
    backgroundColor: 'transparent',
  },
  deleteOption: {
    color: 'red',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  containerBox: {
    backgroundColor: '#FFFFFF',
    padding: '32px',
    borderRadius: '8px',
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 3,
  },
  headerText: {
    fontSize: '20px',
    fontWeight: 700,
    marginBottom: '8px',
  },
  addButton: {
    backgroundColor: '#772EDD',
    color: '#FFFFFF',
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#5C21BF',
    },
  },
  tabsBox: {
    marginBottom: '24px',
    padding: 0,
  },
  tableContainer: {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: 1,
  },
  tableHead: {
    backgroundColor: '#f5f5f5',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
  },
};

const UserManagementTable = ({ accessToken }) => {
  const { selectedClient } = useClient();
  const [users, setUsers] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [invitationStatus, setInvitationStatus] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');

  const sxUserEndpoint = `${process.env.REACT_APP_BACKEND_URL}/admin/users-sx`;
  const tidalUserEndpoint = `${process.env.REACT_APP_BACKEND_URL}/admin/users-tidal`;
  const adminUserEndpoint = `${process.env.REACT_APP_BACKEND_URL}/admin/users-admin`;
  const AUTH0_PROFILE_BASE_URL = process.env.REACT_APP_AUTH0_PROFILE_BASE_URL;
  const BEATDAPP_DOMAIN = 'beatdapp.com';

  useEffect(() => {
    if (!accessToken) {
      return;
    }
    const fetchData = async () => {
      try {
        const userEndpoint = selectedClient === 'SoundExchange' ? sxUserEndpoint : tidalUserEndpoint;

        const responses = await Promise.all([
          fetch(userEndpoint, {
            credentials: 'include',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }),
          fetch(adminUserEndpoint, {
            credentials: 'include',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }),
        ]);

        if (!responses[0].ok) throw new Error(`Failed to fetch ${selectedClient} users`);
        if (!responses[1].ok) throw new Error('Failed to fetch admin users');

        const [userData, adminData] = await Promise.all(responses.map((res) => res.json()));
        const selectedUsers = selectedClient === 'SoundExchange' ? userData.sxUsers || [] : userData.tidalUsers || [];
        setUsers(selectedUsers);
        setAdminUsers(adminData.adminUsers || []);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error fetching users:', err);
      }
    };

    fetchData();
  }, [selectedClient, sxUserEndpoint, tidalUserEndpoint, adminUserEndpoint, accessToken]);

  const isBeatdappUser = (user) => user.email.endsWith(`@${BEATDAPP_DOMAIN}`);
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    return date.toLocaleString('en-US', options);
  };
  const clientUsers = users.filter((user) => !isBeatdappUser(user));
  const beatdappUsers = users.filter(isBeatdappUser);

  const displayedUsers =
    selectedTab === 0
      ? [...users, ...adminUsers]
      : selectedTab === 1
        ? clientUsers
        : selectedTab === 2
          ? beatdappUsers
          : adminUsers;

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const onButtonClick = async (user) => {
    let userType = selectedClient;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/send-invitation`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email, userType: userType, id: user.id }),
      });

      const data = await response.json();

      if (response.ok) {
        alert(`Invitation sent to ${user.email}`);
        setInvitationStatus((prevStatus) => ({
          ...prevStatus,
          [user.email]: 'sent',
        }));

        setTimeout(() => {
          setInvitationStatus((prevStatus) => ({
            ...prevStatus,
            [user.email]: null,
          }));
        }, 5000);
      } else {
        throw new Error(data.message || 'Failed to send invitation');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error sending invitation:', error);
      alert(error.message);
    }
  };

  const handleMenuClick = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const deleteUser = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/user/${userId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete user');
      }

      alert('User deleted successfully');
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      setAdminUsers((prevAdminUsers) => prevAdminUsers.filter((admin) => admin.id !== userId));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error deleting user:', error);
      alert('Failed to delete user');
    }
  };

  const handleOptionClick = (option) => {
    handleMenuClose();

    if (selectedUser) {
      if (option === 'Update User') {
        openModal('Update User', selectedUser);
      } else if (option === 'Delete User') {
        const confirmDelete = window.confirm(`Are you sure you want to delete ${selectedUser.name}?`);
        if (confirmDelete) {
          deleteUser(selectedUser.id);
        }
      } else if (option === 'Go to Auth0 Profile') {
        if (selectedUser.auth0_id) {
          const auth0ProfileUrl = `${AUTH0_PROFILE_BASE_URL}${selectedUser.auth0_id}`;
          window.open(auth0ProfileUrl, '_blank');
        } else {
          alert('Auth0 ID not available for this user.');
        }
      }
    }
  };

  const openModal = (type, user = null) => {
    setModalType(type);
    setSelectedUser(user);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedUser(null);
  };

  return (
    <Box sx={styles.containerBox}>
      <Box sx={styles.headerBox}>
        <Box>
          <Typography sx={styles.headerText}>Users</Typography>
          <Typography>A list of all users that have access to {selectedClient}</Typography>
        </Box>
        <Button variant="contained" onClick={() => openModal('Add New User')} sx={styles.addButton}>
          <UserPlus size={18} style={{ marginRight: '8px' }} />
          Add New User
        </Button>
      </Box>
      <Box sx={styles.tabsBox}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          TabIndicatorProps={{
            sx: styles.tabs.indicatorColor,
          }}
          textColor="primary"
        >
          <Tab disableRipple sx={styles.tab} label="All Users" />
          <Tab disableRipple sx={styles.tab} label={`${selectedClient} Users`} />
          <Tab disableRipple sx={styles.tab} label="Beatdapp Users" />
          <Tab disableRipple sx={styles.tab} label="Admin Users" />
        </Tabs>
      </Box>
      <TableContainer sx={styles.tableContainer}>
        <Table>
          <TableHead sx={styles.tableHead}>
            <TableRow>
              <TableCell sx={styles.tableCell}>Name</TableCell>
              <TableCell sx={styles.tableCell}>Email</TableCell>
              <TableCell sx={styles.tableCell}>User Role</TableCell>
              <TableCell sx={styles.tableCell}>Auth0Id</TableCell>
              <TableCell sx={styles.tableCell}>Last Invited</TableCell>
              <TableCell sx={styles.tableCell}>Invite</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedUsers.map((user, index) => (
              <TableRow key={index} sx={styles.tableRowHover}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.auth0_role_id}</TableCell>
                <TableCell>{user.auth0_id}</TableCell>
                <TableCell>{formatDate(user.invitation_date)}</TableCell>
                <TableCell sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      ...(invitationStatus[user.email] === 'sent' ? styles.sentStatus : styles.buttonBox),
                    }}
                  >
                    {invitationStatus[user.email] === 'sent' ? (
                      <>
                        <Check size={16} />
                        <Typography>Invitation Sent</Typography>
                      </>
                    ) : (
                      <>
                        <Mail />
                        <Button sx={styles.button} onClick={() => onButtonClick(user)}>
                          Send Invitation
                        </Button>
                      </>
                    )}
                  </Box>
                  <IconButton sx={styles.iconButton} onClick={(event) => handleMenuClick(event, user)}>
                    <MoreVertical />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={() => handleOptionClick('Go to Auth0 Profile')}>Go to Auth0 Profile</MenuItem>
        <MenuItem onClick={() => handleOptionClick('Update User')}>Update User</MenuItem>
        <MenuItem onClick={() => handleOptionClick('Delete User')} sx={styles.deleteOption}>
          Delete User
        </MenuItem>
      </Menu>
      <UserManagementModal modalType={modalType} open={modalOpen} onClose={closeModal} selectedUser={selectedUser} accessToken={accessToken} />
    </Box>
  );
};

export default UserManagementTable;
