import React from 'react';
import { Box, Typography } from '@mui/material';
import { Package } from 'lucide-react';
import Layout from '../components/Layout';
import CacheCard from '../components/CacheCard';
import MaintenanceModeCard from '../components/MaintenanceModeCard';

const styles = {
  container: {
    padding: '48px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '32px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 700,
  },
};

const CacheAndMaintenancePage = ({ user, accessToken }) => {
  return (
    <Layout profilePic={user.picture}>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Package size={24} />
          <Typography sx={styles.title}>Cache and Maintenance</Typography>
        </Box>
        <CacheCard accessToken={accessToken} />
        <MaintenanceModeCard accessToken={accessToken} />
      </Box>
    </Layout>
  );
};

export default CacheAndMaintenancePage;
