import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { LayoutPanelLeft } from 'lucide-react';
import Layout from '../components/Layout';
import ClientCard from '../components/ClientCard';
import { useClient } from '../ClientContext';

const styles = {
  container: { padding: '48px' },
  header: { display: 'flex', alignItems: 'center', gap: '8px', mb: '32px' },
  welcomeSection: { mb: '32px' },
  welcomeText: { display: 'flex', gap: '8px', alignItems: 'baseline', mb: '8px' },
  welcomeTitle: { fontSize: '32px', fontWeight: 700 },
  dashboardContainer: { backgroundColor: '#FFFFFF', p: '32px', borderRadius: '8px' },
  dashboardTitle: { fontSize: '20px', fontWeight: 700, mb: '24px' },
  adminPanelTitle: { fontSize: '24px', fontWeight: 700 },
};

const AdminPage = ({ user, accessToken }) => {
  const { selectedClient } = useClient();
  const [error, setError] = useState(null);
  const [sxUsers, setSxUsers] = useState([]);
  const [tidalUsers, setTidalUsers] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);

  const sxUserEndpoint = `${process.env.REACT_APP_BACKEND_URL}/admin/users-sx`;
  const tidalUserEndpoint = `${process.env.REACT_APP_BACKEND_URL}/admin/users-tidal`;
  const adminUserEndpoint = `${process.env.REACT_APP_BACKEND_URL}/admin/users-admin`;

  useEffect(() => {
    if (!accessToken) {
      return;
    }
    const fetchData = async (endpoint, setData) => {
      try {
        const response = await fetch(endpoint, {
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) throw new Error('Failed to fetch data');

        const data = await response.json();
        setData(data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', err);
        setError('Error fetching data.');
      }
    };

    fetchData(sxUserEndpoint, (data) => setSxUsers(data.sxUsers || []));
    fetchData(tidalUserEndpoint, (data) => setTidalUsers(data.tidalUsers || []));
    fetchData(adminUserEndpoint, (data) => setAdminUsers(data.adminUsers || []));
  }, [sxUserEndpoint, tidalUserEndpoint, adminUserEndpoint, accessToken]);

  const countUsers = (sxUsers, tidalUsers, admins) => {
    const seenEmails = new Set();
    let tidalOnlyUsers = 0;
    let sxOnlyUsers = 0;
    let beatdappUsersTidal = 0;
    let beatdappUsersSx = 0;
    const totalAdmins = admins.length;

    sxUsers.forEach((user) => {
      if (!seenEmails.has(user.email)) {
        seenEmails.add(user.email);
        if (user.email.endsWith('@beatdapp.com')) {
          beatdappUsersSx++;
        } else {
          sxOnlyUsers++;
        }
      }
    });

    tidalUsers.forEach((user) => {
      if (!seenEmails.has(user.email)) {
        seenEmails.add(user.email);
        if (user.email.endsWith('@beatdapp.com')) {
          beatdappUsersTidal++;
        } else {
          tidalOnlyUsers++;
        }
      }
    });

    const totalUsersTidal = tidalOnlyUsers + beatdappUsersTidal + totalAdmins;
    const totalUsersSx = sxOnlyUsers + beatdappUsersSx + totalAdmins;

    return {
      tidalStats: {
        totalUsers: totalUsersTidal,
        tidalOnlyUsers,
        beatdappUsers: beatdappUsersTidal,
        totalAdmins,
      },
      sxStats: {
        totalUsers: totalUsersSx,
        sxOnlyUsers,
        beatdappUsers: beatdappUsersSx,
        totalAdmins,
      },
    };
  };

  const { tidalStats, sxStats } = countUsers(sxUsers, tidalUsers, adminUsers);

  const formatStats = (stats, type) => [
    { label: 'Total Users', value: stats.totalUsers ?? 0 },
    {
      label: type === 'TIDAL' ? 'Tidal Only Users' : 'SX Only Users',
      value: type === 'TIDAL' ? (stats.tidalOnlyUsers ?? 0) : (stats.sxOnlyUsers ?? 0),
    },
    { label: 'Beatdapp Users', value: stats.beatdappUsers ?? 0 },
    { label: 'Total Admins', value: stats.totalAdmins ?? 0 },
  ];

  const DASHBOARDS = {
    TIDAL: {
      logo: '/assets/tidal-logo.svg',
      name: 'TIDAL',
      description: 'Tidal Dashboard',
      envKey: 'REACT_APP_DSP_DASH_URL',
      stagingUrl: process.env.REACT_APP_DSP_DASH_URL_STG,
      adminUrl: process.env.REACT_APP_DSP_DASH_ADMIN_URL,
      prodUrl: process.env.REACT_APP_DSP_DASH_URL,
      stats: formatStats(tidalStats, 'TIDAL'),
    },
    SOUND_EXCHANGE: {
      logo: '/assets/sound-exchange-logo.svg',
      name: 'SoundExchange',
      description: 'SoundExchange Dashboard',
      envKey: 'REACT_APP_SX_DASH_URL',
      stagingUrl: process.env.REACT_APP_SX_DASH_URL_STG,
      adminUrl: process.env.REACT_APP_SX_DASH_ADMIN_URL,
      prodUrl: process.env.REACT_APP_SX_DASH_URL,
      stats: formatStats(sxStats, 'SX'),
    },
  };

  const DashboardSection = () => {
    const dashboardsToShow =
      selectedClient === 'All Dashboards'
        ? DASHBOARDS
        : selectedClient === 'Tidal'
          ? { TIDAL: DASHBOARDS.TIDAL }
          : { SOUND_EXCHANGE: DASHBOARDS.SOUND_EXCHANGE };

    return (
      <>
        {Object.entries(dashboardsToShow).map(([key, dashboard]) => (
          <ClientCard
            key={key}
            logo={dashboard.logo}
            name={dashboard.name}
            description={dashboard.description}
            stats={dashboard.stats}
            buttonLabel="Go to Dashboard"
            onButtonClick={() => (window.location.href = dashboard.prodUrl)}
            onGoToStaging={() => (window.location.href = dashboard.stagingUrl)}
            onOpenAdmin={() => (window.location.href = dashboard.adminUrl)}
          />
        ))}
      </>
    );
  };

  if (error) return <div>{error}</div>;

  const formatName = (nickname) => {
    if (!nickname) return '';

    if (!nickname.includes('.')) {
      return nickname;
    }

    const [first, last] = nickname.split('.');
    return `${first.charAt(0).toUpperCase() + first.slice(1)} ${last.charAt(0).toUpperCase() + last.slice(1)}`;
  };

  return (
    <Layout profilePic={user.picture}>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <LayoutPanelLeft size={24} />
          <Typography sx={styles.adminPanelTitle}>Admin Panel</Typography>
        </Box>
        <Box sx={styles.welcomeSection}>
          <Box sx={styles.welcomeText}>
            <Typography sx={styles.welcomeTitle}>Welcome {formatName(user?.nickname)}</Typography>
            <Typography variant="body2" gutterBottom>
              ({user.email})
            </Typography>
          </Box>
          <Typography variant="body2" gutterBottom>
            An overview of all dashboards that you can access
          </Typography>
        </Box>
        <Box sx={styles.dashboardContainer}>
          <Typography sx={styles.dashboardTitle}>Dashboards</Typography>
          <DashboardSection />
        </Box>
      </Box>
    </Layout>
  );
};

export default AdminPage;
